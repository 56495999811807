import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "../services/http.service";
import { ShareDataService } from "../services/share-data.service";
import { Subscription } from "rxjs";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
// import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from "../dialog/dialog.component";
import {ReplaceQuotes} from '../pipes/pipes';

@Component({
  selector: "app-part-selection",
  templateUrl: "./part-selection.component.html",
  styleUrls: ["./part-selection.component.scss"]
})
export class PartSelectionComponent implements OnInit  {

  subscription: Subscription;
  selectedManufacturersData: any;
  allManufacturers: any;
  categories: any;
  models: any;
  subSystems: any;
  parts: any;
  imagePath: string;
  // to know which manufacturer is selected and send api
  defaultManuId: string;
  defaultCatId: any;
  defaultModelId: any;
  defaultSubId: any;
  // hold all select option values
  changedManufacturerID: any;
  changedCategoryID: any;
  changedModelID: any;
  changedSubSystemID: any;
  cartArray: any = [];

  filterKeyword: string;

  constructor(
    private _httpService: HttpService,
    private _sharedService: ShareDataService,
    private _router: Router,
    public dialog: MatDialog
  ) {

  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  displayedColumns: string[] = [
    "Figure",
    " ",
    "  ",
    "Part",
    "Description",
    "Qty. Req'd",
    "Choose Qty",
    "Price(ea)",
    "Sales Price(ea)"
  ];
  dataSource = new MatTableDataSource();

  ngOnInit() {
    this.getSelectedManufacturerData();
    this.dataSource.paginator = this.paginator;
  }

  applyFilter() {
    this.dataSource.filter = this.filterKeyword.trim().toLowerCase();
  }

  openDailog(id) {
    // alert(id);
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      data: { partID: id }
    });
  }

  getSelectedManufacturerData() {
    this.subscription = this._sharedService
      .getSelectedManufacturerID()
      .subscribe(resp => {
        if (resp.exProp) {
          //check if correct properties are assigned
          this.defaultManuId = resp.ManufacturerID;
          this.defaultCatId = resp.ProductCategoryID;
          this.defaultModelId = resp.ProductID;
          this.defaultSubId = resp.DiagramID;
        } else {
          let resp = JSON.parse(localStorage.getItem("ProductData"));
          this.defaultManuId = resp;
        }
      });
    let params = {
      ManufacturerID: this.defaultManuId ? this.defaultManuId : 0,
      CategoryID: this.defaultCatId ? this.defaultCatId : 0,
      ProductID: this.defaultModelId ? this.defaultModelId : 0,
      SubsystemID: this.defaultSubId ? this.defaultSubId : 0
    };
    this._httpService.getSelectedManufacturerData(params).subscribe(resp => {
      this.selectedManufacturersData = resp;
      this.allManufacturers = this.selectedManufacturersData.Item.Manufacturer;
      this.categories = this.selectedManufacturersData.Item.Category;
      this.models = this.selectedManufacturersData.Item.Product;
      this.subSystems = this.selectedManufacturersData.Item.SubSystem;
      this.parts = this.selectedManufacturersData.Item.Parts;
      this.imagePath = this.selectedManufacturersData.Item.Image[0].ImageURL;
      this.dataSource.data = this.parts;
      // this.dataSource.paginator = this.paginator;
    });
  }

  handleOptionChange(manu, cat, model, sub, type) {
    this.filterKeyword = '';
    this.applyFilter();
    if (type == 'manu') {
      this.defaultManuId = manu.value;
      this.defaultCatId = 0;
      this.defaultModelId = 0;
      this.defaultSubId = 0;
    }
    else if (type == 'cat') {
      this.defaultManuId = manu.value;
      this.defaultCatId = cat.value;
      this.defaultModelId = 0;
      this.defaultSubId = 0;
    }
    else if (type == 'model') {
      this.defaultManuId = manu.value;
      this.defaultCatId = cat.value;
      this.defaultModelId = model.value;
      this.defaultSubId = 0;
    }
    else {
      this.defaultManuId = manu.value;
      this.defaultCatId = cat.value;
      this.defaultModelId = model.value;
      this.defaultSubId = sub.value;
    }

    let params = {
      ManufacturerID: this.defaultManuId,
      CategoryID: this.defaultCatId,
      ProductID: this.defaultModelId,
      SubsystemID: this.defaultSubId
    };

    this._httpService.getSelectedManufacturerData(params).subscribe(resp => {
      this.selectedManufacturersData = resp;
      this.allManufacturers = this.selectedManufacturersData.Item.Manufacturer;
      this.categories = this.selectedManufacturersData.Item.Category;
      this.models = this.selectedManufacturersData.Item.Product;
      this.subSystems = this.selectedManufacturersData.Item.SubSystem;
      this.parts = this.selectedManufacturersData.Item.Parts; 
      this.imagePath = this.selectedManufacturersData.Item.Image[0].ImageURL;
      this.paginator.pageIndex = 0;
      this.dataSource.data = this.parts;
      // this.dataSource.paginator = this.paginator;
      // console.log(this.filterKeyword);
    });
  }

  createCart(product: any, quantity: any) {
    if (quantity != "" && quantity != 0) {
      product.reqQuantity = quantity;
      this.cartArray.push(product);
    } else {
      for (let i = 0; i < this.cartArray.length; i++) {
        if (product.PartID == this.cartArray[i].PartID) {
          this.cartArray.splice(i, 1);
        }
      }
    }
  }

  addToCart() {
    let params = "";
    for (let i = 0; i < this.cartArray.length; i++) {
      if (this.cartArray[i].reqQuantity == 1) {
        if (i === 0) {
          params += "ProductCode=" + this.cartArray[i].PartCode;
        } else {
          params += "&ProductCode=" + this.cartArray[i].PartCode;
        }
      } else {
        if (i === 0) {
          params +=
            "ProductCode=" +
            this.cartArray[i].PartCode +
            "&qty." +
            this.cartArray[i].PartCode +
            "=" +
            this.cartArray[i].reqQuantity;
        } else {
          params +=
            "&ProductCode=" +
            this.cartArray[i].PartCode +
            "&qty." +
            this.cartArray[i].PartCode +
            "=" +
            this.cartArray[i].reqQuantity;
        }
      }
    }
    window.location.href = "http://www.gciron.com/ShoppingCart.asp?" + params;
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById("print-section").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

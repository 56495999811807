import { Component, NgModule } from "@angular/core";
import { LoadingIconService } from "./services/loading-icon.service";
import { Subscription } from "rxjs";
import { BrowserModule } from "@angular/platform-browser";
import { AdsenseModule } from "ng2-adsense";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})

export class AppComponent {
  title = "GCIronParts";
  isLoading: Subscription;
  loading: boolean;
  constructor(private _loadingService: LoadingIconService) {}
  ngOnInit() {
    this.isLoading = this._loadingService.isLoading.subscribe(resp => {
      this.loading = resp;
    });
  }

 
  ngOnDestroy() {
    this.isLoading.unsubscribe();
  }
}

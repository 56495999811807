import { Injectable } from "@angular/core";
// import { Observable } from "rxjs";
// import { Subject } from "rxjs";
import { Subject, Observable, of, BehaviorSubject } from "rxjs";

@Injectable()
export class ShareDataService {
  ManufacturerID = new BehaviorSubject<string>("");

  sendSelectedManufacturerID(id) {   
    this.ManufacturerID.next(id);
  }
  getSelectedManufacturerID(): Observable<any> {
    return this.ManufacturerID.asObservable();
  }
}

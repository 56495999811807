import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { PartSelectionComponent } from "./part-selection/part-selection.component";
import { SearchPartComponent } from "./search-part/search-part.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "GCIronPartsHomePage",
          component: HomeComponent,
          data: { isHome: true }
        }, 
        {
          path: "GCIronPartsSelection",
          component: PartSelectionComponent
        },
        {
          path: "PowerPartSearch/:keyword",
          component: SearchPartComponent
        },
        { path: "", redirectTo: "/GCIronPartsHomePage", pathMatch: "full" },
        { path: "**", component: HomeComponent}
      ],
      { enableTracing: false }
    ) 
  ],
  exports: [RouterModule]
})
export class RoutingModule {}

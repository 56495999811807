import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ShareDataService } from "../services/share-data.service";

@Component({
  selector: "app-nav-header",
  templateUrl: "./nav-header.component.html",
  styleUrls: ["./nav-header.component.scss"]
})
export class NavHeaderComponent implements OnInit {
  searchValue: string = "";
  constructor(
    private _router: Router,
    private _sharedService: ShareDataService
  ) {}

  ngOnInit() {
    // if (this.searchValue !== "") {
    //   this.searchKeyword();
    // }
  }
  searchKeyword(keyword:any, option:any) {
    if (keyword.value) {
      this.searchValue = keyword.value;
      if (option.value == "Parts Diagram") {
        this._router.navigate(["/PowerPartSearch", this.searchValue]);
      } else if (option.value == "Data Base") {
        window.location.href =
          "http://www.gciron.com/SearchResults.asp?Search=" + this.searchValue;
      }
    }
    this.searchValue = "";
  }
}

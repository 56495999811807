import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatTableDataSource, PageEvent } from "@angular/material";
import { Subscription } from "rxjs";
import { ShareDataService } from "../services/share-data.service";
import { HttpService } from "../services/http.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-search-part",
  templateUrl: "./search-part.component.html",
  styleUrls: ["./search-part.component.scss"]
})
export class SearchPartComponent implements OnInit {
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  pageEvent: PageEvent;
  subscription: Subscription;
  keyword: string = "";

  //All dropdowns data
  categories: any;
  subCategories: any;
  manufacturers: any;
  models: any;
  subSystems: any;
  price: any;
  searchResult: any = [];
  // to know which manufacturer is selected and send api
  defaultManuId: string;
  defaultCatId: any;
  defaultModelId: any;
  defaultSubId: any;
  // hold all select option values
  changedCategoryID: any;
  changedSubCategoryID: any;
  changedManufacturerID: any;
  changedModelID: any;
  changedSubSystemID: any;
  changedPrice: any;
  displayedColumns: string[] = [
    "Result",
    "Part#",
    "Description",
    "Product",
    "subSystem",
    "Price",
    "SalePrice",
    "AltPartId"
  ];
  dataSource = new MatTableDataSource();

  index: number = 0;
  rowCount: number;
  pageIndex: number = 0;
  pageSize: number = 10;

  constructor(
    private _sharedService: ShareDataService,
    private _httpService: HttpService,
    private _router: Router,
    private _activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this._activeRoute.params.subscribe(params => {
      this.keyword = params["keyword"];
      // console.log(this.keyword);
      this.getSearchResult('true');
    });
    this.getAllCategories();
    this.getAllManufacturers();
    this.getAllModels();
    this.dataSource.paginator = this.paginator;
  }
  getEditedKeywordData(keywordRef) {
    //  console.log(this.keyword);
    // if(keywordRef != null){
    //   this.keyword = keywordRef.value;
    //   this.getSearchResult();     
    // // this._router.navigate(["/PowerPartSearch/:+this.keyword+"]);
    // this._router.navigate(['/PowerPartSearch', {keyword: this.keyword}]);
    // }
    // this.ngOnInit();
  }
  getNextPageData(event) {
    if (event.pageIndex === this.pageIndex + 1) {
      this.index = this.index + 1;
      this.getSearchResult('false');
    } else if (event.pageIndex === this.pageIndex - 1) {
      this.index = this.index - 1;
      this.getSearchResult('false');
    } else if (
      event.pageIndex === Math.ceil(this.rowCount / this.pageSize) ||
      event.pageIndex === Math.floor(this.rowCount / this.pageSize)
    ) {
      this.index = event.pageIndex;
      this.getSearchResult('false');
      // console.log(event.pageIndex);
    } else if (event.pageIndex === 0) {
      this.index = event.pageIndex;
      this.getSearchResult('false');
    }
    this.pageIndex = event.pageIndex;
  }

  getAllCategories() {
    let params = {
      ParentCategoryID: 0
    };
    this._httpService.getAllCategories(params).subscribe((resp: any) => {
      if (resp) {
        this.categories = resp.Items;
      }
    });
  }
  getAllManufacturers() {
    let params = {
      CategoryID: 0
    };
    this._httpService.getAllManufacturers(params).subscribe((resp: any) => {
      if (resp) {
        this.manufacturers = resp.Items;
      }
    });
  }
  getAllModels() {
    let params = {
      CategoryID: 0,
      ManufacturerID: 0
    };
    this._httpService.getAllModels(params).subscribe((resp: any) => {
      if (resp) {
        this.models = resp.Items;
      }
    });
  }
  getSearchResult(firstPage) {
    // alert(this.keyword);
    let params = {
      ParentCategoryID: this.changedCategoryID ? this.changedCategoryID : 0,
      CategoryID: this.changedSubCategoryID ? this.changedSubCategoryID : 0,
      ManufacturerID: this.changedManufacturerID
        ? this.changedManufacturerID
        : 0,
      ProductID: this.changedModelID ? this.changedModelID : 0,
      DiagramID: this.changedSubSystemID ? this.changedSubSystemID : 0,
      Price: this.changedPrice ? this.changedPrice : 0,
      RowsToReturn: 10,
      Index: this.index,
      SortColumn: "Price",
      SortDir: 1,
      // SearchString: "steering"
      SearchString: this.keyword
    };
    this._httpService.getSearchResult(params).subscribe((resp: any) => {
      if (resp.Item) {
        this.searchResult = resp.Item.PartSearchModel;
        this.rowCount = resp.Item.RowCount[0] ? resp.Item.RowCount[0].TotalRows : 0;
        if(firstPage == 'true'){
          this.paginator.pageIndex = 0;
        }     
        this.dataSource = new MatTableDataSource(this.searchResult);
        // this.dataSource.paginator = this.paginator;
      }
    });
  }

  handleCategory(event) {
    this.changedCategoryID =
      event.target.value !== "All" ? event.target.value : 0;
    // get subcategory
    let params1 = {
      ParentCategoryID: this.changedCategoryID
    };
    this._httpService
      .handleCategorySubCategory(params1)
      .subscribe((resp: any) => {
        if (resp) {
          this.subCategories = resp.Items;
        }
      });
    // get manufacturers
    let params2 = {
      CategoryID: this.changedCategoryID
    };
    this._httpService
      .handleCategoryManufacturer(params2)
      .subscribe((resp: any) => {
        if (resp) {
          this.manufacturers = resp.Items;
        }
      });
    // get models
    let params3 = {
      CategoryID: this.changedCategoryID,
      ManufacturerID: 0
    };
    this._httpService.handleCategoryModel(params3).subscribe((resp: any) => {
      if (resp) {
        this.models = resp.Items;
      }
    });
    // get table data
    this.getSearchResult('true');
  }

  handleSubCategory(event) {
    this.changedSubCategoryID =
      event.target.value !== "All" ? event.target.value : 0;
    let params1 = {
      CategoryID: this.changedSubCategoryID
    };
    this._httpService.handleSubCategoryManu(params1).subscribe((resp: any) => {
      if (resp) {
        this.manufacturers = resp.Items;
      }
    });
    // get models
    let params2 = {
      CategoryID: this.changedSubCategoryID,
      ManufacturerID: 0
    };
    this._httpService.handleSubCategoryModel(params2).subscribe((resp: any) => {
      if (resp) {
        this.models = resp.Items;
      }
    });
    //get table data
    this.getSearchResult('true');
  }

  handleManufacturer(event) {
    this.changedManufacturerID =
      event.target.value !== "All" ? event.target.value : 0;
    // get models
    let params1 = {
      CategoryID: this.changedSubCategoryID ? this.changedSubCategoryID : 0,
      ManufacturerID: this.changedManufacturerID
        ? this.changedManufacturerID
        : 0
    };

    this._httpService.handleManuModel(params1).subscribe((resp: any) => {
      if (resp) {
        this.models = resp.Items;
      }
    });
    // get table data
    this.getSearchResult('true');
  }
  handleModel(event) {
    this.changedModelID = event.target.value !== "All" ? event.target.value : 0;
    //get subsystems
    let params1 = {
      ProductID: this.changedModelID
    };
    this._httpService.handleModelSub(params1).subscribe((resp: any) => {
      if (resp) {
        this.subSystems = resp.Items;
      }
    });
    //get table data
    this.getSearchResult('true');
  }
  handleSubSystem(event) {
    this.changedSubSystemID =
      event.target.value !== "All" ? event.target.value : 0;
    //get table data
    this.getSearchResult('true');
  }
  handlePrice(event) {
    this.changedPrice = event.target.value !== "All" ? event.target.value : 0;
    //get table data
    this.getSearchResult('true');
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getPartDetails(element) {
    element.exProp = "extra";
    this._sharedService.sendSelectedManufacturerID(element);
    this._router.navigate(["/GCIronPartsSelection"]);
  }
}

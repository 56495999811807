import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { NavHeaderComponent } from "./nav-header/nav-header.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { NavSearchComponent } from "./nav-search/nav-search.component";
import { PartSelectionComponent } from "./part-selection/part-selection.component";
import { SearchPartComponent } from "./search-part/search-part.component";
import { RoutingModule } from "./routing";
import { MaterialModule } from "./material-module/material-module";
import { HttpService } from "./services/http.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ShareDataService } from "./services/share-data.service";

import { ImageZoomModule } from "angular2-image-zoom";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { HttpClientInterceptor } from "./services/http-client.interceptor";
import { LoadingIconService } from "./services/loading-icon.service";
import { LoadingIconComponent } from "./loading-icon/loading-icon.component";
import { DialogComponent } from './dialog/dialog.component';
import { ReplaceQuotes } from "./pipes/pipes";
import { AdsenseModule } from "ng2-adsense";

@NgModule({
  declarations: [
    AppComponent,
    NavHeaderComponent,
    FooterComponent,
    HomeComponent,
    NavSearchComponent,
    PartSelectionComponent,
    SearchPartComponent,
    LoadingIconComponent,
    DialogComponent,
    ReplaceQuotes
  ],
  imports: [
    BrowserModule,
    NgxImageZoomModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    RoutingModule,
    HttpClientModule,
    FormsModule,
    ImageZoomModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-4302045039625015'
     
    }),    
  ],
  entryComponents: [DialogComponent],
  providers: [
    HttpService,
    ShareDataService,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpClientInterceptor,
        multi: true
      }
    ],
    HttpClientInterceptor,
    LoadingIconService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, NgModule } from "@angular/core";
import { HttpService } from "../services/http.service";
import { ShareDataService } from "../services/share-data.service";

import { AdsenseModule } from 'ng2-adsense';
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "../app.component";
import { Template } from "@angular/compiler/src/render3/r3_ast";


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html" ,
  styleUrls: ["./home.component.scss"]
})

export class HomeComponent implements OnInit {
  allManufacturersData: object;
  categoryDefault: any;
  modalDefault: any;
  subDefault: any;
  constructor(
    private _httpService: HttpService,  
    private _sharedService: ShareDataService
  ) {
    // console.log(window.location.pathname);
  }

  ngOnInit() {}

  manufacturerSelected(id:number) {
    this._sharedService.sendSelectedManufacturerID(id);
    localStorage.setItem("ProductData", JSON.stringify(id));
  }  
 
  
}


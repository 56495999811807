import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { MatCardModule, MatSelectModule } from "@angular/material";
import {
	// MatAutocompleteModule,
	MatButtonModule,
	MatFormFieldModule,
	MatCardModule,
	MatIconModule,
	MatInputModule,
	MatTableModule,
	// MatMenuModule,
	MatTooltipModule,
	// MatSliderModule,
	// MatCheckboxModule,
	MatChipsModule,
	MatSelectModule,
	MatPaginatorModule,
	// MatTabsModule,

	MatDialogModule,
	// MatListModule,
	// MatRadioModule,
	//MatButtonToggleModule,
	// MatDatepickerModule,
	// MatDialogModule,
	MatDividerModule
	// MatExpansionModule,
	// MatGridListModule,
	// MatListModule,
	// MatNativeDateModule,
	// MatProgressBarModule,
	// MatProgressSpinnerModule,

	// MatRippleModule,
	// MatSidenavModule,
	// MatSlideToggleModule,
	// MatSnackBarModule,
	// MatSortModule,
	// MatStepperModule,

	// MatTabsModule,
	// MatToolbarModule}
} from '@angular/material';
@NgModule({
	imports: [
		// CommonModule,
		// MatAutocompleteModule,
		MatButtonModule,
		MatFormFieldModule,
		MatCardModule,
		MatIconModule,
		MatInputModule,
		MatTableModule,
		// MatInputModule,
		MatTooltipModule,
		// MatSliderModule,
		// MatCheckboxModule,
		MatChipsModule,
		MatSelectModule,
		MatPaginatorModule,
		// MatTabsModule,
		MatDialogModule,
		// MatListModule,
		// MatRadioModule,
		//MatButtonToggleModule,
		// MatStepperModule,
		// MatDatepickerModule,
		// MatDialogModule,
		MatDividerModule
		// MatExpansionModule,
		// MatGridListModule,
		// MatListModule,
		// MatMenuModule,
		// MatNativeDateModule,
		// MatProgressBarModule,
		// MatProgressSpinnerModule,
		// MatRippleModule,
		// MatSidenavModule,
		// MatSlideToggleModule,
		// MatSnackBarModule,
		// MatSortModule,

		// MatToolbarModule,
		// MatNativeDateModule,
	],
	declarations: [],
	exports: [
		// MatAutocompleteModule,
		MatButtonModule,
		MatFormFieldModule,
		MatCardModule,
		MatIconModule,
		MatInputModule,
		MatTableModule,
		// MatMenuModule,
		MatTooltipModule,
		// MatSliderModule,
		// MatCheckboxModule,
		MatChipsModule,
		MatSelectModule,
		MatPaginatorModule,
		// MatTabsModule,

		MatDialogModule,
		// MatListModule,
		// MatRadioModule,
		// MatButtonToggleModule,
		// MatStepperModule,
		// MatDatepickerModule,
		// MatDialogModule,
		MatDividerModule
		// MatExpansionModule,
		// MatGridListModule,
		// MatListModule,
		// MatNativeDateModule,
		// MatProgressBarModule,
		// MatProgressSpinnerModule,
		// MatRippleModule,
		// MatSidenavModule,
		// MatSlideToggleModule,
		// MatSnackBarModule,
		// MatSortModule,
		// MatTableModule,
		// MatToolbarModule,
		// MatNativeDateModule
	]
})
export class MaterialModule {}

import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { AppUrls } from "src/environments/app-urls";

@Injectable()
export class HttpService {
  private allMacufacturers: Observable<any> = null;
  private allCategories: Observable<any> = null;
  private allModels: Observable<any> = null;
  
  url: string = AppUrls._baseUrl;

  constructor(private http: HttpClient) {}

  getSelectedManufacturerData(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("ManufacturerID", params.ManufacturerID);
    paramData = paramData.append("CategoryID", params.CategoryID);
    paramData = paramData.append("ProductID", params.ProductID);
    paramData = paramData.append("SubsystemID", params.SubsystemID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetPartsSelectionByIDs";
    // return this.http.get(url, { params: paramData });
    return this.http.get(this.url+'GetPartsSelectionByIDs', { params: paramData });
  }
  getAllManufacturers(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("CategoryID", params.CategoryID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetActiveManufatures";
    if (this.allMacufacturers === null) {
      this.allMacufacturers = this.http
        .get(this.url+'GetActiveManufatures', { params: paramData })
        .pipe(shareReplay(1));
    }
    return this.allMacufacturers;
  }
  getAllCategories(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("ParentCategoryID", params.ParentCategoryID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetActiveCategoriesByManufacture";
    if (this.allCategories === null) {
      this.allCategories = this.http
        .get(this.url+'GetActiveCategoriesByManufacture', { params: paramData })
        .pipe(shareReplay(1));
    }
    return this.allCategories;
  }
  getAllModels(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("CategoryID", params.CategoryID);
    paramData = paramData.append("ManufacturerID", params.ManufacturerID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetActiveModulesByManu";
    if (this.allModels === null) {
      this.allModels = this.http
        .get(this.url+'GetActiveModulesByManu', { params: paramData })
        .pipe(shareReplay(1));
    }
    return this.allModels;
  }
  getSearchResult(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("ParentCategoryID", params.ParentCategoryID);
    paramData = paramData.append("CategoryID", params.CategoryID);
    paramData = paramData.append("ManufacturerID", params.ManufacturerID);
    paramData = paramData.append("ProductID", params.ProductID);
    paramData = paramData.append("DiagramID", params.DiagramID);
    paramData = paramData.append("Price", params.Price);
    paramData = paramData.append("RowsToReturn", params.RowsToReturn);
    paramData = paramData.append("Index", params.Index);
    paramData = paramData.append("SortColumn", params.SortColumn);
    paramData = paramData.append("SortDir", params.SortDir);
    paramData = paramData.append("SearchString", params.SearchString);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/powerPartSearch";
    return this.http.get(this.url+'powerPartSearch', { params: paramData });
  }
  handleCategorySubCategory(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("ParentCategoryID", params.ParentCategoryID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetActiveCategoriesByManufacture";
    return this.http.get(this.url+'GetActiveCategoriesByManufacture', { params: paramData });
  }
  handleCategoryManufacturer(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("CategoryID", params.CategoryID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetActiveManufatures";
    return this.http.get(this.url+'GetActiveManufatures', { params: paramData });
  }
  handleCategoryModel(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("CategoryID", params.CategoryID);
    paramData = paramData.append("ManufacturerID", params.ManufacturerID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetActiveModulesByManu";
    return this.http.get(this.url+'GetActiveModulesByManu', { params: paramData });
  }
  handleSubCategoryManu(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("CategoryID", params.CategoryID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetActiveManufatures";
    return this.http.get(this.url+'GetActiveManufatures', { params: paramData });
  }
  handleSubCategoryModel(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("CategoryID", params.CategoryID);
    paramData = paramData.append("ManufacturerID", params.ManufacturerID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetActiveModulesByManu";
    return this.http.get(this.url+'GetActiveModulesByManu', { params: paramData });
  }
  handleManuModel(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("CategoryID", params.CategoryID);
    paramData = paramData.append("ManufacturerID", params.ManufacturerID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetActiveModulesByManu";
    return this.http.get(this.url+'GetActiveModulesByManu', { params: paramData });
  }
  handleModelSub(params) {
    let paramData = new HttpParams();
    paramData = paramData.append("ProductID", params.ProductID);
    // let url =
    //   "http://gcironparts.technodemos.com/api/IronParts/GetSubSystemByProduct";
    return this.http.get(this.url+'GetSubSystemByProduct', { params: paramData });
  }
}
